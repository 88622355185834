import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=61451f2a&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=61451f2a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61451f2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Nav: require('/home/travis/build/Polumathes/LTNG-WBN-Frontend/components/Nav.vue').default})
